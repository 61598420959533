import * as React from 'react';

import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import IStateRegisterUser from '../interfaces/IStateRegisterUser';
class RegisterUser extends React.PureComponent<{}, IStateRegisterUser> {
    constructor(props: {}) {
        super(props)
        this.state = {
            userName: "",
            password: "",
            confirmPassword: "",
            errors: []
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.renderErrors = this.renderErrors.bind(this)

    }

    private renderErrors() {
        return this.state.errors.length > 0 && < Alert color="danger" >
            <ul>
                {this.state.errors.map(m => { return <li>{m}</li>; })}
            </ul>
        </Alert>;
    }

    private validateForm() {
        this.setState({
            errors: []
        })
        let formError = []

        if (this.state.userName.length === 0 || this.state.password.length === 0) {
            formError.push("Ingrese los datos");
        }

        if (this.state.confirmPassword !== this.state.password) {
            formError.push("Las contraseñas no coinciden");
        }

        if (formError.length > 0) {
            this.setState({
                errors: formError
            })
            return false;
        }
        return true;
    }

    private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (!this.validateForm()) {
            console.log(this.state.errors)
            return
        };

        let body = {
            email: this.state.userName,
            password: this.state.password,
            passwordConfirm: this.state.password
        }
        //para enviar archivos hay que usar el objeto form data de javascript y enviarlo en el parámetro body
        let requestInit: RequestInit = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        fetch('api/account/register', requestInit).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    localStorage.setItem("token", data.token);
                    window.location.href = "/";
                });
            }
            else {
                this.setState({
                    errors: ["Error desconocido, intente de nuevo"]
                })
            }
        })
    }


    public render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <h1>Registro</h1>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" className="form-control mb-2 mr-sm-2" placeholder="Email"
                                    value={this.state.userName}
                                    onChange={
                                        (e: React.FormEvent<HTMLInputElement>) =>
                                            this.setState({
                                                userName: e.currentTarget.value
                                            })
                                    } />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" className="form-control mb-2 mr-sm-2" placeholder="Contraseña"
                                    value={this.state.password}
                                    onChange={
                                        (e: React.FormEvent<HTMLInputElement>) =>
                                            this.setState({
                                                password: e.currentTarget.value
                                            })
                                    } />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" className="form-control mb-2 mr-sm-2" placeholder="Confirmar contraseña"
                                    value={this.state.confirmPassword}
                                    onChange={
                                        (e: React.FormEvent<HTMLInputElement>) =>
                                            this.setState({
                                                confirmPassword: e.currentTarget.value
                                            })
                                    } />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Completar Registro</button>
                            </div>
                            {this.renderErrors()}
                            <div>
                                <label>
                                    ¿Ya estás registrado?
                                    <Link to="/login">Inicia sesión!</Link>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default RegisterUser;