import * as React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import IStateLogin from '../interfaces/IStateLogin';

export default class Login extends React.PureComponent<{}, IStateLogin> {
    constructor(props: {}) {
        super(props)
        this.state = {
            userName: "test@localhost.com",
            password: "test@localhost.com",
            errors: []
        }

        if (localStorage.getItem("token")) {
            window.location.href = "/";
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.renderErrors = this.renderErrors.bind(this)

    }
    private renderErrors() {
        return this.state.errors.length > 0 && < Alert color="danger" >
            <ul>
                {this.state.errors.map(m => { return <li key={m}>{m}</li>; })}
            </ul>
        </Alert>;
    }
    private validateForm() {
        this.setState({
            errors: []
        })
        let formError = []
        if (this.state.userName.length === 0 || this.state.password.length === 0) {
            formError.push("Ingrese el usuario y/o contraseña");
        }

        if (formError.length > 0) {
            this.setState({
                errors: formError
            })
            return false;
        }
        return true;
    }

    private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (!this.validateForm()) {
            console.log(this.state.errors)
            return
        };

        let body = {
            email: this.state.userName,
            password: this.state.password
        }
        //para enviar archivos hay que usar el objeto form data de javascript y enviarlo en el parámetro body
        let requestInit: RequestInit = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        fetch('api/account/login', requestInit).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    localStorage.setItem("token", data.token);
                    window.location.href = "/";
                });
            }
            else {
                this.setState({
                    errors: ["Usuario y/o contraseña incorrectos"]
                })
            }
        })
    }


    public render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12">
                        <h1>Inicio de sesión</h1>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>Usuario</label>
                                <input type="text" className="form-control mb-2 mr-sm-2" placeholder="Usuario"
                                    value={this.state.userName}
                                    onChange={
                                        (e: React.FormEvent<HTMLInputElement>) =>
                                            this.setState({
                                                userName: e.currentTarget.value
                                            })
                                    } />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" className="form-control mb-2 mr-sm-2" placeholder="Contraseña"
                                    value={this.state.password}
                                    onChange={
                                        (e: React.FormEvent<HTMLInputElement>) =>
                                            this.setState({
                                                password: e.currentTarget.value
                                            })
                                    } />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary">Iniciar Sesión</button>
                            </div>
                            {this.renderErrors()}
                            <div>
                                <label>
                                    ¿Todavía no estás registrado?
                                    <Link to="/register">Registrate ahora!</Link>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}