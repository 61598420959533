import * as React from 'react';
import ITaxPayer from '../interfaces/ITaxPayer';
import { Alert } from 'reactstrap';

class SearchResult extends React.Component<ITaxPayer,{}> {

    public render() {
        let alertVariant = this.props.estatus === 'NoEncontrado' ? 'alert alert-primary':'alert alert-danger';
        return (
            <div className='form-group'>
                <div className={alertVariant} role='alert'>
                    <h5>
                        <strong>RFC</strong>: {this.props.rfc} <br />
                        <strong>Razón social</strong>: {this.props.razonSocial} <br />
                        <strong>Estatus</strong> :<strong>{this.props.estatus}</strong><br />
                    </h5>
                    <hr />
                    {this.props.estatus != 'NoEncontrado' && 
                        <div>
                            <strong>Detalle completo</strong>:<br />
                                RFC: {this.props.rfc}<br />
                                Razón social: {this.props.razonSocial}<br />
                                Estatus: {this.props.estatus}<br />
                                Número de Oficio Presunto SAT: {this.props.numOficioPresuntoSAT ? this.props.numOficioPresuntoSAT : 'N/A'}<br />
                                Número de Oficio Presunto DOF: {this.props.numOficioPresuntoDOF ? this.props.numOficioPresuntoDOF : 'N/A'}<br />
                                Número de Oficio Desvirtuado SAT: {this.props.numOficioDesvirtuadoSAT ? this.props.numOficioDesvirtuadoSAT : 'N/A'}<br />
                                Número de Oficio Definitivo SAT: {this.props.numOficioDefinitivoSAT ? this.props.numOficioDefinitivoSAT : 'N/A'}<br />
                                Número de Oficio Sentencia Favorable SAT: {this.props.numOficioSentenciaFavorableSAT ? this.props.numOficioSentenciaFavorableSAT : 'N/A'}<br />
                                Numero de Oficio Sentencia Favorable DOF: {this.props.numOficioSentenciaFavortableDOF ? this.props.numOficioSentenciaFavortableDOF : 'N/A'}<br />
                                Detalle de búsqueda {this.props.detalle}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default SearchResult;