import * as React from 'react';


const FAQ = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h1>Preguntas Frecuentes</h1>
                <ul>
                    <li>Pregunta: ¿Por qué entra en la lista negra una empresa?</li>
                    <li>Respuesta: Incumplimiento de las obligaciones fiscales. Cuando una persona física o moral no cumple con sus obligaciones (pago de impuestos) o hay sospecha de transacciones ilícitas (ej, lavado de dinero), esas personas se vuelven sujeto de ser agregados en la lista negra</li>
                </ul>
                <ul>
                    <li>Pregunta: ¿Que implica para mi el que uno o varios de mis proveedores esten en la lista negra?</li>
                    <li>Respuesta: Todos los proveedores y clientes que tengan facturas con esa persona que está en la lista negra, no pueden presentar sus declaraciones con esas operaciones por lo cuál les "rebotan" todas sus operaciones</li>
                </ul>
                <ul>
                    <li>Pregunta: ¿Qué debo hacer yo si sospecho que uno de mis proveedores realiza operaciones ilicitas?</li>
                    <li>Respuesta: Es obligación tuya como persona física o moral estar seguro de que no tienes operaciones con un proveedor o cliente que está en la lista negra.
                        <br />
                        Si sospechas que alguno de tus proveedores realiza operaciones ilicitas o incumple sus obligaciones fiscales debes avisar al SAT lo antes posible.
                    </li>
                </ul>
                <ul>
                    <li>Pregunta: ¿Puedo realizar validaciones masivas?</li>
                    <li>Respuesta: Por supuesto, contactanos y te armamos un paquete para que puedas validar usando un archivo excel o alguno de los endpoint REST o SOAP.</li>
                </ul>
                <ul>
                    <li>Pregunta: ¿Qué tan seguido se actualiza esta lista?</li>
                    <li>Respuesta: Nosotros realizamos una actualización diaria de la información para estar al día con el SAT</li>
                </ul>
                <ul>
                    <li>Pregunta: ¿Qué otra información puedo obtener si uso el servicio completo?</li>
                    <li>Respuesta: El número de oficio y fecha en que se declara un contribuyente como Presunto o Definitivo por el SAT así como las fechas en que se pública dicha información en el Diario Oficial de la Federación entre otros datos</li>
                </ul>
            </div>
        </div>
    </div>
);

export default FAQ;
