import * as React from 'react';
import IArchiveHistoryList from '../interfaces/IArchiveHistoryList';

class ArchiveHistory extends React.Component<{}, IArchiveHistoryList>
{
    constructor(props: {}) {
        super(props);
        this.state = {
            archiveHistory: [],
            archiveSearch:[],
            searchTerm:'',
            errors:[]
        };
    }

    componentWillMount() {
        this.retrieveDocumentList();
    }

    private retrieveDocumentList() {
        let url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        fetch(`${url}api/blacklist/documentList`)
            .then(res => {
                if (res.status === 200) {
                    res.json().then(data => {
                        const result = [];
                        const map = new Map();
                        for (const item of data) {
                            if (!map.has(item.searchDetails)) {
                                map.set(item.searchDetails, true);
                                result.push({
                                    searchDetails:item.searchDetails.substr(0,46),
                                    externalFileId: item.externalFileId
                                });
                            }
                        }
                        this.setState({ archiveHistory: result})
                    });
                }
                else if (res.status !== 200) {
                    res.json().then(data => {
                        if(Array.isArray(data.details)){
                            this.setState({ errors: data.details });
                        }
                        else{ 
                            this.setState({ errors: [data.details] });
                        }
                    });
                }
            })
    }

    private renderTable(items: Array<any>) {

        const notResults = (<div>No se encontraron resultados </div>)

        return (
            items.length > 0 ?
            items.map(item =>
                (<div key={item.externalFileId}>
                    <a href={`api/blacklist/document/${item.externalFileId}`} download>
                        {item.searchDetails}
                    </a>
                </div>)
                )
                :
                notResults
                );
    }

    private handleSubmit(event: any) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="container">
                <h1>Hist&oacute;rico de archivos del SAT</h1>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="text" className="form-control mb-2 mr-sm-2" placeholder="Búsqueda"
                            value={this.state.searchTerm}
                            onChange={
                                (e: React.FormEvent<HTMLInputElement>) =>
                                    this.setState({
                                        searchTerm: e.currentTarget.value.toUpperCase(),
                                        archiveSearch: this.state.archiveHistory.filter((item) => { return item.searchDetails.toUpperCase().includes(e.currentTarget.value.toUpperCase()) })
                                    })} />
                    </div>
                </form>
                {(this.state.archiveSearch.length > 0 || this.state.searchTerm.length > 0) ? this.renderTable(this.state.archiveSearch) : this.renderTable(this.state.archiveHistory)}
            </div>
        );
    }
}

export default ArchiveHistory;