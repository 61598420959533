import * as React from 'react';


const Contact = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h1>Contacto</h1>
                Ventas: Cristhian Rosillo - Cristhian.Rosillo@outlook.com <br />
                Desarrollo: Lucio Flores - lucio.flores@live.com
            </div>
        </div>
    </div>
);

export default Contact;