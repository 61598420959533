import * as React from 'react';

const NoticyOfPrivacy = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h1>Aviso de Privacidad</h1>
                <p>
                    El ciudadano Lucio Flores con domicilio en la Colonia Santa Clara Coatitla CP 5540. Ecatepec de Morelos. EdoMex. conforme a lo establecido en la Ley Federal de Protección de Datos en Posesión de Particulares, pone a disposición de nuestro público en general, el Aviso de Privacidad.
                </p>
                <p>
                    Los datos personales que nos proporciona son utilizados estrictamente en la realización de funciones propias del portal de La Lista Negra del SAT y por ningún motivo serán transferidos a terceros.
                </p>
                <p>
                    A nuestro público Usuario
                    <ul>
                        <li>Dirección IP</li>
                        <li>Cadena UserAgent del explorador de internet</li>
                    </ul>
                </p>
                <p>
                    A nuestros clientes les solicitamos los siguientes datos personales:
                    <ul>
                        <li>Nombre, teléfono y correo electrónico para facilitar el proceso de contratación de nuestro servicio y/o compra de nuestros productos.</li>
                        <li>
                            RFC para completar los datos de facturación y realizar el proceso en cuestión.
                        </li>
                    </ul>
                </p>

                <p>
                    A nuestros proveedores solicitamos los siguientes datos personales:
                    <ul>
                        <li>Nombre, teléfono, correo electrónico y domicilio para facilitar el proceso de contratación de servicio y/o compra de productos.</li>
                        <li>Datos bancarios para realizar los pagos correspondientes.</li>
                    </ul>
                </p>
                <p>
                    A nuestros empleados solicitamos los siguientes datos personales:
                    <ul>
                        <li>Nombre, teléfono, correo electrónico, domicilio, fecha y lugar de nacimiento.</li>
                        <li>Antecedentes laborales</li>
                    </ul>
                </p>

                <p>
                    En caso de realizar modificaciones al presente Aviso de Privacidad, le informaremos mediante nuestro sitio web oficial
                </p>
            </div>
        </div>
    </div>
);

export default NoticyOfPrivacy;