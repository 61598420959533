import * as React from 'react';
import { Link } from 'react-router-dom';
import IValidateInvoice from '../interfaces/IValidateInvoice';

class ValidateInvoice extends React.Component<{}, IValidateInvoice>
{
    constructor(props: {}) {
        super(props)
        this.state = {
            file: new Blob(),
            validationResult: {
                blackListStatus: '',
                structure: '',
                validationDate: '',
                validity: '',
                emisorName: '',
                emisorRfc: '',
                receptorRfc: '',
                receptorName: '',
            },
            errors: []
        }
        this.sendFile = this.sendFile.bind(this);
        this.handleInputFileChange = this.handleInputFileChange.bind(this);
        this.renderErrors = this.renderErrors.bind(this);
    }

    sendFile(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        let url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        let appKey = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY_PROD;

        let formData = new FormData();
        if (this.state.file.size == 0) {
            this.setState({ errors: ['Seleccione un archivo'] })
            return;
        }

        formData.append('invoice', this.state.file);
        this.setState({
            validationResult:{
                blackListStatus: '',
                structure: '',
                validationDate: '',
                validity: '',
                emisorName: '',
                emisorRfc: '',
                receptorRfc: '',
                receptorName: '',
            }
        });
        fetch(`${url}api/invoice?rfc=FOGL900501K94&appkey=${appKey}`, {
            method: 'POST',
            body: formData
        }).then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    this.setState({
                        validationResult: {
                            blackListStatus: data.estatusListaNegra,
                            structure: data.estructura,
                            validationDate: data.fechaDeValidacion,
                            validity: data.vigencia,
                            emisorRfc: data.emisorRfc,
                            emisorName: data.emisorNombre,
                            receptorName: data.receptorNombre,
                            receptorRfc: data.receptorRfc
                        },
                        errors:[]
                    })
                });
            }
            else {
                res.json().then(data => {
                    if (Array.isArray(data.details)) {
                        this.setState({ errors: data.details });
                    }
                    else {
                        this.setState({ errors: [data.details] });
                    }
                });
            }
        });
    }

    handleInputFileChange(event: React.FormEvent<HTMLInputElement>) {
        if (event.currentTarget.files != undefined) {
            let file = event.currentTarget.files[0];
            this.setState({ file: file });
        }
    }

    renderErrors() {
        const elements = this.state.errors;
        return (
            <div className="form-group">
                <div className="alert alert-warning">
                <h5>Errores</h5>
                <ul>
                {elements.map((value, index) => {
                    return <li key={index} role="alert">{value}</li>

                })}
                </ul>
                </div>
            </div>
        )
    }

    renderValidationResult() {
        return (
            <div>
                <div className='form-group'>
                    <div role='alert' className="alert alert-success">
                        <h5>
                            <strong>Resultados validación:</strong>
                        </h5>
                        <strong>Emisor</strong>: {`${this.state.validationResult.emisorName} (${this.state.validationResult.emisorRfc})`}<br />
                        <strong>Receptor</strong>: {`${this.state.validationResult.receptorName} (${this.state.validationResult.receptorRfc})`}<br />
                        <strong>Estatus Lista Negra Emisor:</strong>: {this.state.validationResult.blackListStatus}<br />
                        <strong>Estructura</strong>: {this.state.validationResult.structure}<br />
                        <strong>Fecha de validación</strong>: {this.state.validationResult.validationDate}<br />
                        <strong>Vigencia</strong>: {this.state.validationResult.validity}<br />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <h1>Valida tu factura</h1>
                        <p>En esta sección del portal puedes validar tu factura en XML.</p>
                        <p>Ingresa el archivo XML y da clic en Validar para conocer el resultado</p>
                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <form onSubmit={this.sendFile} className="form-inline">
                            <div className="form-group">
                                <input type="file" name="file"
                                    className="form-control mb-2 mr-sm-2" placeholder="Selecciona un archivo"
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => this.handleInputFileChange(e)} />
                            </div>
                            <button type="submit">Validar</button>
                        </form>
                        {this.state.validationResult.blackListStatus.length > 0 && this.renderValidationResult()}
                        {this.state.errors.length > 0 && this.renderErrors()}
                    </div>
                </div>
            </div>
        )
    }

}
export default ValidateInvoice;