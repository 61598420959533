import * as React from 'react';
import {
    BrowserRouter,
    Route
} from "react-router-dom";
import Layout from './components/Layout';
import Home from './components/Home';
import FAQ from './components/FAQ';
import ForDevelopers from './components/ForDevelopers';
import Contact from './components/Contact';
import './custom.css'
import './dashboard.css'
import Login from './components/Login';
import ArchiveHistory from './components/ArchiveHistory';
import UserDashboard from './components/UserDashboard';
import UserDashboardRFCs from './components/UserDashboardRFCs';
import UserDashboardReports from './components/UserDashboardReports';
import RegisterUser from './components/RegisterUser';
import UserDashboardSideBar from './components/UserDashboardSideBar';
import NoticyOfPrivacy from './components/NoticyOfPrivacy';
import ValidateInvoice from './components/ValidateInvoice';
import { Switch } from 'react-router';

function LogOut() {
    localStorage.removeItem("token");
    window.location.href = "/login";
    return (<></>);
}

let isLogged = localStorage.getItem("token");

function Dashboard() {
    return (<div className="row">
        <UserDashboardSideBar />
        <UserDashboard />
    </div>);
}


function MyRFC() {
    return (<div className="row">
        <UserDashboardSideBar />
        <UserDashboardRFCs />
    </div>);
}


function Reports() {
    return (<div className="row">
        <UserDashboardSideBar />
        <UserDashboardReports />
    </div>);
}

class App extends React.Component<{}, {}>{

    private logOut() {

    }

    public ClearCacheData() {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    public render() {
        this.ClearCacheData();
        return (
            <BrowserRouter>
                <Layout>
                </Layout>
                <Switch>
                    <Route exact path='/' component={!isLogged ? Home : Dashboard} />
                    <Route path='/faq' component={FAQ} />
                    <Route path='/forDevs' component={ForDevelopers} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/noticyOfPrivacy' component={NoticyOfPrivacy} />
                    <Route path='/login' component={Login} />
                    <Route path='/logout'>
                        <LogOut />
                    </Route>
                    <Route path='/register' component={RegisterUser} />
                    <Route path='/archives' component={ArchiveHistory} />
                    <Route path='/misrfcs' component={MyRFC} />
                    <Route path='/reports' component={Reports} />
                    <Route path='/invoiceValidation' component={ValidateInvoice}/>
                    <Route path='/black-list'>
                        <div className="row">
                            <UserDashboardSideBar />
                            <Home />
                        </div>
                    </Route>
                </Switch>
            </BrowserRouter>);
    }
}

export default App;