import * as React from 'react';
import NavMenu from './NavMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu/>
        <div className="container-fluid">
            {props.children}
        </div>
        <footer className="footer">
            <div className="container">
                <span className="text-muted">
                    <a href='https://blog.lalistanegradelsat.com.mx/index.php' className="text-dark nav-link">Blog</a>
                </span>
            </div>
        </footer>
    </React.Fragment>
);
