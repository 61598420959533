import * as React from 'react';
import { Alert } from 'reactstrap';

interface IUserDashboardRFCs {
    Ciec: string,
    Rfc: string,
    ShortName: string,
    errors: Array<string>
}

class UserDashboardRFCs extends React.PureComponent<{}, IUserDashboardRFCs>{

    constructor(props: {}) {
        super(props);
        if (!localStorage.getItem("token")) {
            window.location.href = '/login';
        }
        this.state = {
            Rfc: '',
            Ciec: '',
            ShortName: '',
            errors: []
        }
        this.validateForm = this.validateForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderUploadRfcForm = this.renderUploadRfcForm.bind(this);
    }

    private validateForm() {
        this.setState({
            errors: []
        })
        let formError = []
        if (this.state.ShortName.trim().length === 0
            ||
            this.state.Rfc.trim().length === 0
            ||
            this.state.Ciec.trim().length === 0) {
            formError.push("Ingrese los datos");
        }

        //validate rfc
        let rfcIsValid = /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/.test(this.state.Rfc);
        if (!rfcIsValid) {
            formError.push("Introduzca un RFC válido");
        }
        

        if (formError.length > 0) {
            this.setState({
                errors: formError
            })
            return false;
        }
        return true;
    }

    private renderErrors() {
        return this.state.errors.length > 0 && <Alert color="danger" >
            <ul>
                {this.state.errors.map(m => { return <li key={m}>{m}</li>; })}
            </ul>
        </Alert>;
    }

    private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!this.validateForm()) return;

        let body = {
            Name: this.state.ShortName,
            RFC: this.state.Rfc,
            CIEC: this.state.Ciec
        }

        let requestInit: RequestInit = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        }

        fetch('api/rfc', requestInit).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    console.log(data);
                });
            }
            else {
                this.setState({
                    errors: ["No se pudo guardar la información. Intente de nuevo."]
                })
            }
        })
    }

  
    public renderUploadRfcForm() {
        return (<>
            <div className="col-md-12 col-lg-12 col-sm-12">
                <form className="form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="text" className="form-control mb-2 mr-sm-2" placeholder="Nombre corto del RFC"
                            value={this.state.ShortName}
                            onChange={
                            (e: React.FormEvent<HTMLInputElement>) =>
                                this.setState({
                                    ShortName: e.currentTarget.value
                                })
                        }/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control mb-2 mr-sm-2" placeholder="RFC"
                            value={this.state.Rfc}
                            onChange={
                            (e: React.FormEvent<HTMLInputElement>) =>
                                this.setState({
                                    Rfc: e.currentTarget.value
                                })
                        }/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control mb-2 mr-sm-2" placeholder="CIEC"
                            value={this.state.Ciec}
                            onChange={
                                (e: React.FormEvent<HTMLInputElement>) =>
                                    this.setState({
                                        Ciec: e.currentTarget.value
                                    })
                            }/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                    {this.renderErrors()}
                </form>
            </div>
        </>
            );
    }
    
    public render() {
        return (<>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Mis RFCs</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>
                {this.renderUploadRfcForm()}
            </main>
        </>)
    }
}
export default UserDashboardRFCs;