import * as React from 'react';


const ForDevelopers = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h1>Para Desarrolladores</h1>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12">
                <p>Eres desarrollador y quieres realizar b&uacute;squedas desde tu sistema? Usa el API REST o el endpoint SOAP</p>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h2>REST</h2>
                <fieldset>
                    <legend>EndPoint</legend>
                    <section>
                        <code>
                            HTTP:GET lalistanegradelsat.com.mx/api/blacklist/AAA091014835 <br />
                        </code>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Response RFC Encontrado</legend>
                    <section>
                        <pre>
                            <code>
                                &#123;<br />
                                &#9;&quot;RFC&quot;:&quot;AAA091014835&quot;,<br />
                                &#9;&quot;RazonSocial&quot;:&quot;EMPRESA SA DE CV, S.C.&quot;,<br />
                                &#9;&quot;Estatus&quot;:&quot;Desvirtuado&quot;,<br />
                                &#9;&quot;Detalle&quot;:&quot;Informaci&oacute;n actualizada al 27 de abril de 2020&quot;<br />
                                &#125;
                            </code>
                        </pre>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Response RFC No Encontrado</legend>
                    <section>
                        <pre>
                            <code>
                                null
                            </code>
                        </pre>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Bad Request</legend>
                    <section>
                        <pre>
                            <code>
                                &#123;<br/>
                                &#9;&quot;messages&quot;:[<br />
                                &#9;&#9;&quot;El RFC enviado no es v&aacute;lido&quot;,<br />
                                &#9;&#9;&quot;Otros mensajes...&quot;<br />
                                &#9;]<br/>
                                &#125;
                            </code>
                        </pre>
                    </section>
                </fieldset>
            </div>
            <hr/>
            <div className="col-md-12 col-lg-12 col-sm-12">
                <h2>SOAP</h2>
                <fieldset>
                    <legend>EndPoint</legend>
                    <section>
                        <code>
                            https://soap.lalistanegradelsat.com.mx?wsdl<br />
                        </code>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Request</legend>
                    <section>
                        <pre>
                            <code>
                                &lt;soapenv:Envelope xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns:soapenv=&quot;http://schemas.xmlsoap.org/soap/envelope/&quot; xmlns:soap=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br />
                                &#9;&lt;soapenv:Header/&gt;<br />
                                &#9;&lt;soapenv:Body&gt;<br />
                                &#9;&#9;&lt;soap:search soapenv:encodingStyle=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot;&gt;<br />
                                &#9;&#9;&#9;&lt;taxPayer xsi:type=&quot;soap:taxPayer&quot;&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;RFC xsi:type=&quot;xsd:string&quot;&gt;AAA091014835&lt;/RFC&gt;<br />
                                &#9;&#9;&#9;&lt;/taxPayer&gt;<br />
                                &#9;&#9;&lt;/soap:search&gt;<br />
                                &#9;&lt;/soapenv:Body&gt;<br />
                                &lt;/soapenv:Envelope&gt;<br />
                            </code>
                        </pre>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Response RFC Encontrado</legend>
                    <section>
                        <pre>
                            <code>
                                &lt;SOAP-ENV: Envelope SOAP-ENV:encodingStyle=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot; xmlns:SOAP-ENV=&quot;http://schemas.xmlsoap.org/soap/envelope/&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:SOAP-ENC=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot; xmlns:tns=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br/>
                                &#9;&lt;SOAP-ENV: Body&gt;<br />
                                &#9;&#9;&lt;ns1:searchResponse xmlns:ns1=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br />
                                &#9;&#9;&#9;&lt;return xsi: type=&quot;tns:taxPayer&quot;&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;RFC xsi:type=&quot;xsd:string&quot;&gt;AAA091014835&lt;/RFC&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;RazonSocial xsi:type=&quot;xsd:string&quot;&gt;Empresa SA DE CV&lt;/RazonSocial&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;Estatus xsi:type=&quot;xsd:string&quot;&gt;Definitivo&lt;/Estatus&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;Detalle xsi: type=&quot;xsd:string&quot;&gt;Información actualizada al 27 de abril de 2020.&lt;/Detalle&gt;<br />
                                &#9;&#9;&#9;&lt;/return&gt;<br />
                                &#9;&#9;&lt;/ns1: searchResponse&gt;<br />
                                &#9;&lt;/SOAP-ENV: Body&gt;<br />
                                &lt;/SOAP-ENV:Envelope&gt;<br />
                            </code>
                        </pre>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Response RFC No Encontrado</legend>
                    <section>
                        <pre>
                            <code>
                                &lt;SOAP-ENV:Envelope SOAP-ENV:encodingStyle=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot; xmlns:SOAP-ENV=&quot;http://schemas.xmlsoap.org/soap/envelope/&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:SOAP-ENC=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot; xmlns:tns=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br />
                                &#9;&lt;SOAP-ENV:Body&gt;<br />
                                &#9;&#9;&lt;ns1:searchResponse xmlns:ns1=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br />
                                &#9;&#9;&#9;&lt;return xsi:nil=&quot;true&quot; xsi:type=&quot;tns:taxPayer&quot;/&gt;<br />
                                &#9;&#9;&lt;/ns1:searchResponse&gt;<br />
                                &#9;&lt;/SOAP-ENV:Body&gt;<br />
                                &lt;/SOAP-ENV:Envelope&gt;
                            </code>
                        </pre>
                    </section>
                </fieldset>
                <fieldset>
                    <legend>Bad Request</legend>
                    <section>
                        <pre>
                            <code>
                                &lt;SOAP-ENV: Envelope SOAP-ENV:encodingStyle=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot; xmlns:SOAP-ENV=&quot;http://schemas.xmlsoap.org/soap/envelope/&quot; xmlns:xsd=&quot;http://www.w3.org/2001/XMLSchema&quot; xmlns:xsi=&quot;http://www.w3.org/2001/XMLSchema-instance&quot; xmlns:SOAP-ENC=&quot;http://schemas.xmlsoap.org/soap/encoding/&quot; xmlns:tns=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br />
                                &#9;&lt;SOAP-ENV: Body&gt;<br />
                                &#9;&#9;&lt;ns1:searchResponse xmlns:ns1=&quot;soap.lalistanegradelsat.com.mx&quot;&gt;<br />
                                &#9;&#9;&#9;&lt;return xsi: type=&quot;tns:taxPayer&quot;&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;RFC xsi: nil=&quot;true&quot; xsi:type=&quot;xsd:string&quot;/&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;RazonSocial xsi: nil=&quot;true&quot; xsi:type=&quot;xsd:string&quot;/&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;Estatus xsi: nil=&quot;true&quot; xsi:type=&quot;xsd:string&quot;/&gt;<br />
                                &#9;&#9;&#9;&#9;&lt;Detalle xsi: type=&quot;xsd:string&quot;&gt;El RFC enviado no es válido.&lt;/Detalle&gt;<br />
                                &#9;&#9;&#9;&lt;/return&gt;<br />
                                &#9;&#9;&lt;/ns1: searchResponse&gt;<br />
                                &#9;&lt;/SOAP-ENV: Body&gt;<br />
                                &lt;/SOAP-ENV:Envelope&gt;<br />
                            </code>
                        </pre>
                    </section>
                </fieldset>
            </div>
        </div>
    </div>
);

export default ForDevelopers;