import * as React from 'react';
import SearchResult from './SearchResult';
import ITaxPayer from '../interfaces/ITaxPayer';
import IStateSearchTaxPayerForm from '../interfaces/IStateSearchTaxPayerForm';

class SearchTaxPayerForm extends React.PureComponent<{}, IStateSearchTaxPayerForm> {

    constructor(props: {}) {
        super(props);
        this.state = {
            rfc: "",
            errors: [],
            taxPayer: {
                rfc: '',
                razonSocial: '',
                estatus: '',
                detalle: '',
                numOficioPresuntoSAT: '',
                numOficioPresuntoDOF: '',
                numOficioDesvirtuadoSAT: '',
                numOficioDefinitivoSAT: '',
                numOficioSentenciaFavorableSAT: '',
                numOficioSentenciaFavortableDOF: ''
            },
            searched: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validateForm = this.validateForm.bind(this)
    }

    private validateForm() {
        if (this.state.rfc.length === 0) {
            this.setState({ errors: ["Introduzca un RFC antes de hacer la búsqueda"] })
            return false;
        }
        if (/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/.test(this.state.rfc) == false)
        {
            this.setState({ errors: ["Introduzca un RFC válido antes de hacer la búsqueda"] })
            return false;
        }
        return true;
    }

    private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (this.validateForm()) {
            //console.log(this.state);
            let url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
            this.setState(
                {
                    taxPayer: {
                        rfc: '',
                        razonSocial: '',
                        estatus: '',
                        detalle: '',
                        numOficioPresuntoSAT: '',
                        numOficioPresuntoDOF: '',
                        numOficioDesvirtuadoSAT: '',
                        numOficioDefinitivoSAT: '',
                        numOficioSentenciaFavorableSAT: '',
                        numOficioSentenciaFavortableDOF: ''
                    },
                    searched: false,
                    errors: []
                }
            );
            

            fetch(`${url}api/blacklist/${this.state.rfc}`)
                .then(res => {
                    if (res.status === 200) {
                        res.json().then(data => {
                            if (data !== null) {
                                let taxPayer: ITaxPayer = {
                                    rfc: data.rfc,
                                    razonSocial: data.razonSocial,
                                    estatus: data.estatus,
                                    detalle: `${data.detalle.comments}`,
                                    numOficioPresuntoSAT: data.numOficioPresuntoSAT,
                                    numOficioPresuntoDOF: data.numOficioPresuntoDOF,
                                    numOficioDesvirtuadoSAT: data.numOficioDesvirtuadoSAT,
                                    numOficioDefinitivoSAT: data.numOficioDefinitivoSAT,
                                    numOficioSentenciaFavorableSAT: data.numOficioSentenciaFavorableSAT,
                                    numOficioSentenciaFavortableDOF: data.numOficioSentenciaFavortableDOF
                                };
                                this.setState({ taxPayer: taxPayer, searched: true });
                            }
                            else {
                                this.setState({ searched: true });
                            }
                        })
                    }
                    else if (res.status === 404) {
                        res.json().then(data => {
                            if (data !== null) {
                                let taxPayer: ITaxPayer = {
                                    rfc: data.rfc,
                                    razonSocial: data.razonSocial,
                                    estatus: data.estatus,
                                    detalle: `${data.detalle.comments}`,
                                    numOficioPresuntoSAT: data.numOficioPresuntoSAT,
                                    numOficioPresuntoDOF: data.numOficioPresuntoDOF,
                                    numOficioDesvirtuadoSAT: data.numOficioDesvirtuadoSAT,
                                    numOficioDefinitivoSAT: data.numOficioDefinitivoSAT,
                                    numOficioSentenciaFavorableSAT: data.numOficioSentenciaFavorableSAT,
                                    numOficioSentenciaFavortableDOF: data.numOficioSentenciaFavortableDOF
                                };
                                this.setState({ taxPayer: taxPayer, searched: true });
                            }
                            else {
                                this.setState({ searched: true });
                            }
                        })
                    }
                    else if (res.status !== 200) {
                        res.json().then(data => {
                            if(Array.isArray(data.details)){
                                this.setState({ searched: false, errors: data.details });
                            }
                            else{ 
                                this.setState({ searched: false, errors: [data.details] });
                            }
                        });
                    }
                })
        }
    }

    private renderErrors() {
        const elements = this.state.errors;
        return (
            <div className="form-group">
                {elements.map((value, index) => {
                    return <div className="alert alert-warning" key={index} role="alert">{value}</div>

                })}
            </div>
        )
    }

    public render() {
        return (
            <div className="col-md-12 col-lg-12 col-sm-12">
                <form className="form-inline" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="text" className="form-control mb-2 mr-sm-2" placeholder="Escribe un RFC"
                            value={this.state.rfc}
                            onChange={
                                (e: React.FormEvent<HTMLInputElement>) =>
                                    this.setState({ rfc: e.currentTarget.value.toUpperCase(), errors: [], searched: false })} />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">Buscar</button>
                    </div>
                </form>
                {
                    this.state.searched && this.state.taxPayer.rfc !== "" &&
                    <SearchResult {...this.state.taxPayer}/>
                }
                {
                    this.state.searched && this.state.taxPayer.rfc === "" &&
                    <div className="form-group">
                        <div className="alert alert-primary" role="alert">
                            El emisor con RFC {this.state.rfc} no est&aacute; en lista negra
                        </div>
                    </div>
                }
                {this.renderErrors()}
            </div>
        );
    }
}

export default SearchTaxPayerForm;